import { gql } from '@apollo/client';

export const GET_ALL_REPORTS = gql`
	query GetAllReports($companyId: ID!, $offset: Int!) {
		getAllReports(companyId: $companyId, offset: $offset) {
			count
			hasMore
			reports {
				status
				id
				companyId
				tokens
				createdAt
				updatedAt
				deletedAt
				businessIdea
				language
				type
				title
			}
		}
	}
`;

export const GET_USER_LOGS = gql`
	query GetUserLogs($userId: ID!) {
		getUserLogs(userId: $userId) {
			loginTime
			logoutTime
			ipAddress
		}
	}
`;

export const REPORTS_DEATAIL = gql`
	query Query($reportId: ID!, $userId: ID!) {
		getReport(reportId: $reportId, userId: $userId) {
			status
			businessIdea
			stringifiedReport
		}
	}
`;

export const ADMIN_GET_REPORT = gql`
	query GetAdminReport($reportTemplateId: ID!) {
		getAdminReport(reportTemplateId: $reportTemplateId) {
			status
			stringifiedReport
			title
		}
	}
`;

export const ADMIN_GET_REPORT_TEMPLATES = gql`
	query GetAllReportTemplates($offset: Int!, $limit: Int) {
		getAllReportTemplates(offset: $offset, limit: $limit) {
			reportTemplates {
				id
				title
				is_published
				report_type_id
				status
				language
				tokens
				template_image
				createdAt
				updatedAt
				deletedAt
				templateReportTypes {
					id
					reportType
					reportDescription
					reportCategory
				}
			}
			hasMore
			count
		}
	}
`;

export const GET_REPORT_TEMPLATES = gql`
	query GetAllReportTemplatesClientSide($offset: Int!, $limit: Int) {
		getAllReportTemplatesClientSide(offset: $offset, limit: $limit) {
			reportTemplates {
				id
				title
				is_published
				report_type_id
				status
				language
				tokens
				template_image
				templateReportTypes {
					id
					reportType
					reportDescription
					reportCategory
				}
				createdAt
				updatedAt
				deletedAt
			}
			hasMore
			count
		}
	}
`;

export const GET_REPORT_USAGE = gql`
	query GetReportUsage($userId: ID!) {
		getReportUsage(userId: $userId) {
			advance
			simpleAndStandard
		}
	}
`;

export const GET_PLAN_USAGE = gql`
	query GetPlanUsage($userId: ID!) {
		getPlanUsage(userId: $userId) {
			advance
			simpleAndStandard
		}
	}
`;

export const GET_USER = gql`
	query GetUser($userId: ID!) {
		getUser(userId: $userId) {
			company {
				VAT
				address
				city
				companyName
				country
				createdAt
				deletedAt
				id
				regNumber
				updatedAt
				zipCode
			}
			plan {
				id
				name
				isYearly
				isMonthly
				planId
				noOfSimpleReports
				noOfAdvanceReports
				noOfStandardReports
				maxTokens
				ideaLength
				pdfWatermark
				newFeatures
				supportTeam
				apiAccess
				customWatermark
				createdAt
				deletedAt
				updatedAt
			}
			createdAt
			deletedAt
			firstName
			email
			id
			isSubscriptionActive
			isSuspended
			lastName
			planId
			stripeId
			updatedAt
			role
			preferences {
				emailYrSubscriptionRenew
				emailPlanUpgrade
				emailReport
			}
		}
	}
`;

export const GET_ALL_REPORTS_ADMIN = gql`
	query GetAllReportTypes($adminId: ID!) {
		getAllReportTypes(adminId: $adminId) {
			success
			reportTypes {
				reportTitle
				reportDescription
				reportType
				reportCategory
				outputButtonLabel
				prompt
				maxTokens
				temperature
				model
				order
				questions {
					shortCode
					question
					maxLength
					minLength
					enableFileInput
					id
					type
				}
				id
			}
			message
		}
	}
`;

export const GET_ANSWER_BY_REPORTID = gql`
	query GetAnswersByReportId($reportId: ID!, $userId: ID!) {
		getAnswersByReportId(reportId: $reportId, userId: $userId) {
			allAnswers {
				id
				question
				enableFileInput
				fileType
				fileName
				fileUrl
				answer
				maxLength
				minLength
			}
			newlyAddedQuestions {
				id
				question
				maxLength
				minLength
				enableFileInput
				fileType
				fileName
				fileUrl
			}
			reportTitle
			language
			isReportTypeDeleted
		}
	}
`;

// previous api
// query GetAnswersByReportId($reportId: ID!, $userId: ID!) {
// 	getAnswersByReportId(reportId: $reportId, userId: $userId) {
// 		allAnswers {
// 			question
// 			answer
// 			maxLength
// 			minLength
// 			id
// 		}
// 		language
// 		reportTitle
// 		isReportTypeDeleted
// 		newlyAddedQuestions {
// 			question
// 			maxLength
// 			minLength
// 			enableFileInput
// 			id
// 		}
// 	}
// }

export const GET_ALL_REPORT_TYPE = gql`
	query ReportType {
		getAllUserReportTypes {
			id
			reportType
			reportTitle
			reportCategory
			reportDescription
			outputButtonLabel
			questions {
				enableFileInput
				question
				maxLength
				minLength
				type
				id
			}
		}
	}
`;

export const GET_GENERATED_REPORT_STATUS = gql`
	query GetGeneratedReportStats($userId: String) {
		getGeneratedReportStats(userId: $userId) {
			inCompleteReports
			completeReports
		}
	}
`;

export const GET_ALL_USERS = gql`
	query GetAllUsers($offset: Int!) {
		getAllUsersByAdmin(offset: $offset) {
			hasMore
			count
			users {
				id
				firstName
				lastName
				email
				role
				isSuspended
				plan {
					id
					name
					isYearly
					isMonthly
					planId
					noOfSimpleReports
					noOfAdvanceReports
					noOfStandardReports
					maxTokens
					ideaLength
					pdfWatermark
					newFeatures
					supportTeam
					apiAccess
					customWatermark
					createdAt
					deletedAt
					updatedAt
				}
				subscriptionTime {
					time
				}
			}
		}
	}
`;

export const GET_USER_REPORT_STATS_BY_ADMIN = gql`
	query GetUserReportStatsByAdmin($userId: ID!) {
		getUserReportStatsByAdmin(userId: $userId) {
			id
			noOfSimpleReports
			noOfAdvanceReports
			noOfStandardReports
			generatedReports
			incompleteReports
			noOfTokens
		}
	}
`;

export const SEARCH_USER = gql`
	query SearchUsers($query: String!) {
		searchUsers(query: $query) {
			id
			firstName
			lastName
			email
			createdAt
			deletedAt
			updatedAt
			isSubscriptionActive
			isSuspended
			planId
			stripeId
			role
			company {
				id
				companyName
				address
				country
				city
				zipCode
				regNumber
				VAT
				createdAt
				deletedAt
				updatedAt
			}
			plan {
				id
				name
				isYearly
				isMonthly
				planId
				noOfSimpleReports
				noOfAdvanceReports
				noOfStandardReports
				maxTokens
				ideaLength
				pdfWatermark
				newFeatures
				supportTeam
				apiAccess
				customWatermark
				createdAt
				deletedAt
				updatedAt
			}
			preferences {
				emailYrSubscriptionRenew
				emailPlanUpgrade
				emailReport
			}
		}
	}
`;

export const GET_TEMPLATE = gql`
	query GetTemplate($templateId: ID!) {
		getTemplate(template_id: $templateId) {
			status
			stringifiedReport
			title
		}
	}
`;

export const GET_REPORT_FEEDBACK_BY_ID = gql`
	query GetReportFeedbackById($id: String!) {
		getReportFeedbackById(id: $id) {
			id
			rating
			reportId
			createdAt
			updatedAt
			tblReport {
				id
				type
				title
				language
				company_id
				status
				userId
				reportTypeId
				tokens
				order
				createdAt
				updatedAt
				deletedAt
				tblUser {
					id
					firstName
					lastName
					email
					role
				}
				tblReportType {
					id
					reportTitle
					reportType
					reportCategory
					model
					createdAt
					updatedAt
					deletedAt
				}
			}
		}
	}
`;

export const GET_ALL_REPORT_FEEDBACK = gql`
	query GetAllReportFeedback($limit: Int, $offset: Int) {
		getAllReportFeedback(limit: $limit, offset: $offset) {
			reportfeedback {
				id
				rating
				reportId
				createdAt
				updatedAt
				tblReport {
					id
					type
					title
					language
					company_id
					status
					userId
					reportTypeId
					tokens
					order
					createdAt
					updatedAt
					deletedAt
					tblUser {
						id
						firstName
						lastName
						email
						role
					}
					tblReportType {
						id
						reportTitle
						reportType
						reportCategory
						model
						createdAt
						updatedAt
						deletedAt
					}
				}
				success
				message
			}
			hasMore
			count
		}
	}
`;

export const GET_ALL_USER_SUGGESTION = gql`
	query GetAllUsersSuggestionByAdmin {
		getAllUsersSuggestionByAdmin {
			id
			firstName
			lastName
			email
			suggestions {
				id
				suggestions
			}
		}
	}
`;
