export const withoutLayoutRoutes = [
	'/pricing',
	'/unsubscribe',
	'/report-templates',
	'/report-template',
];
export const error = [
	'Your account has been suspended by the Admin. Contact support@ainova.io for more information',
	'Invalid username/password',
];
