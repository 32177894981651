import React, { useEffect, useState } from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
	GET_ALL_REPORT_FEEDBACK,
	GET_REPORT_FEEDBACK_BY_ID,
	REPORTS_DEATAIL,
} from '../../../Api/Query';
import Spinner from '../../../components/bootstrap/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import noReport from '../../../assets/svgImg/noReports.svg';
import { GETREPORTS, MESSAGE, STATUS } from '../../../reducer/Types';
import Page from '../../../layout/Page/Page';
import ReportIdeaHeader from '../../../components/report/reportIdeaHeader/ReportIdeaHeader';
import '../../../styles/pages/Report.scss';
import ToManyRequest from './helper/toManyRequest/ToManyRequest';
import ReportDetailsPDF from './ReportDetailsPDF';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Icon from '../../../components/icon/Icon';
import { SEND_REPORT_FEEDBACK } from '../../../Api/Mutation';
import showNotification from '../../../components/extras/showNotification';

const HTMLResponse = ({ htmlString }: any) => {
	return <div className='reportMain' dangerouslySetInnerHTML={{ __html: htmlString }} />;
};

const ReportsDetail = () => {
	const { id, type }: any = useParams();
	const dispatch = useDispatch();
	const [reportStatus, setReportStatus] = useState<string | undefined>('');
	const [rating, setRating] = useState<number>(0);
	const [hover, setHover] = useState<number>(0);
	const [feedbackId, setFeedbackId] = useState<string | null>(null);

	const { getReport, user } = useSelector((state: any) => state);

	const {
		data: queryData,
		loading: queryLoading,
		error: queryError,
	} = useQuery(REPORTS_DEATAIL, {
		variables: { reportId: id, userId: user?.id },
		pollInterval: reportStatus === 'GENERATING' ? 10000 : 0,
	});

	const { data } = useQuery(GET_ALL_REPORT_FEEDBACK, {
		fetchPolicy: 'network-only',
		initialFetchPolicy: 'cache-only',
	});

	const [getReportFeedbackById, { data: feedBackData }] = useLazyQuery(
		GET_REPORT_FEEDBACK_BY_ID,
		{
			onCompleted: (data) => {
				const feedback = data?.getReportFeedbackById;
				if (feedback) {
					console.log({ rating: feedback.rating });
					setFeedbackId(feedback.id);
					setRating(feedback.rating);
				}
			},
		},
	);

	const [reportFeedback] = useMutation(SEND_REPORT_FEEDBACK);

	const [isloading, setIsLoading] = useState(true);
	const IdeaArr = JSON.parse(getReport?.businessIdea || '[]');
	const reportHtml = !!getReport?.stringifiedReport && JSON.parse(getReport?.stringifiedReport);
	const [htmlString, setHtmlString] = useState('');

	const handleClick = async (index: number) => {
		try {
			console.log({ index });
			setRating(index);
			const idToUse = feedbackId || feedBackData?.getReportFeedbackById?.id;
			const { data } = await reportFeedback({
				variables: {
					rating: index,
					id: idToUse || null,
					reportId: id,
				},
			});

			if (data.reportFeedback.success) {
				setFeedbackId(data.reportFeedback.id);
				showNotification(
					<span className='d-flex align-items-center'>
						<span>{data.reportFeedback.message}</span>
					</span>,
					"Thank you for your feedback! We've already generated a report based on your input.",
				);
			} else {
				handleError(data.reportFeedback.message);
			}
		} catch (error: any) {
			handleError(error.message);
		}
	};

	const handleError = (message: string) => {
		dispatch({ type: MESSAGE, payload: message });
		dispatch({ type: STATUS, payload: false });
	};

	useEffect(() => {
		if (data?.getAllReportFeedback?.reportfeedback?.length > 0) {
			getReportFeedbackById({ variables: { id } });
		}
	}, [data, id, getReportFeedbackById]);

	useEffect(() => {
		setHtmlString(reportHtml);
		console.log({ reportHtml });
	}, [getReport?.stringifiedReport]);

	const idea = IdeaArr?.map((Item: any, i: Number, arr: any) => (
		<li
			style={{
				listStyle: arr?.length === 1 ? 'none' : 'auto',
				textAlign: arr?.length === 1 ? 'center' : 'start',
			}}>
			{Item}
		</li>
	));

	useEffect(() => {
		setReportStatus(queryData?.getReport?.status);
		if (queryData?.getReport?.status === 'GENERATING') {
			setIsLoading(true);
		} else if (queryData?.getReport?.status === 'SUCCESS') {
			dispatch({ type: GETREPORTS, payload: queryData?.getReport });
			setIsLoading(false);
		} else if (queryData?.getReport?.status === 'PLAN_EXCEED') {
			setIsLoading(false);
			dispatch({ type: GETREPORTS, payload: [] });
		} else if (queryData?.getReport?.status === 'TOO_MANY_REQUESTS') {
			setIsLoading(false);
			dispatch({ type: GETREPORTS, payload: [] });
		}
		if (queryError?.graphQLErrors) {
			setReportStatus('ERROR');
			setIsLoading(false);
			dispatch({ type: GETREPORTS, payload: [] });
		}
	}, [queryData?.getReport, queryError?.graphQLErrors]);

	return (
		<PageWrapper>
			<React.Fragment>
				{isloading || queryLoading ? (
					<div
						className='d-flex align-items-center justify-content-center'
						style={{ height: '90vh' }}>
						<Spinner isGrow={true} color={'dark'} size={'5vh'} />
						<span style={{ paddingLeft: '10px' }}>
							Your report is generating, it may take from 30 seconds to couple of
							minutes to complete
						</span>
					</div>
				) : (
					<Page>
						{queryData?.getReport?.status === 'TOO_MANY_REQUESTS' ? (
							<ToManyRequest />
						) : (
							<div className='reportDetail'>
								<div className='reportDetailSheet'>
									<div className='reportDetailheadContainer'>
										<h1 className='reportDetailhead'>{type}</h1>
										{htmlString && (
											<PDFDownloadLink
												document={
													<ReportDetailsPDF
														type={type}
														IdeaArr={IdeaArr}
														idea={idea}
														htmlString={htmlString}
													/>
												}
												fileName={`${type}.pdf`}>
												<Icon
													icon='Download'
													size={'5x'}
													className='downloadIcon'
												/>
											</PDFDownloadLink>
										)}
									</div>
									<div
										className='shadow-lg  my-4  bg-white rounded d-block'
										style={{ padding: '2rem' }}>
										{!!IdeaArr?.length ? (
											<React.Fragment>
												<ReportIdeaHeader
													text={
														IdeaArr?.length === 1
															? 'Business Idea'
															: 'Your Questionnaire'
													}
													idea={idea}
													hover={hover}
													rating={rating}
													handleClick={handleClick}
													setHover={setHover}
													check={htmlString}
												/>
												<HTMLResponse htmlString={htmlString} />
											</React.Fragment>
										) : (
											queryData?.getReport?.status === 'PLAN_EXCEED' && (
												<div className='Nojanerated'>
													<img src={noReport} alt='' />
													<h1>
														Plan Exceeded! Upgrade Now for Continued
														Access.
													</h1>
												</div>
											)
										)}
									</div>
								</div>
							</div>
						)}
					</Page>
				)}
			</React.Fragment>
		</PageWrapper>
	);
};

export default ReportsDetail;
