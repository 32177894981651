import React, { FC, useState } from 'react';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../components/bootstrap/Card';
import Button from '../../components/bootstrap/Button';
import Chart from '../../components/extras/Chart';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../components/bootstrap/Dropdown';
import Badge from '../../components/bootstrap/Badge';
import { priceFormat } from '../../helpers/helpers';
import showNotification from '../../components/extras/showNotification';
import Icon from '../../components/icon/Icon';
import { demoPagesMenu } from '../../menu';
import useDarkMode from '../../hooks/useDarkMode';
import { ApexOptions } from 'apexcharts';
import { Link } from 'react-router-dom';
import Spinner from '../../components/bootstrap/Spinner';

interface ICommonGridProductItemProps {
	id: string | number;
	name: string;
	category: string;
	img?: string;
	color?: string;
	series?: ApexOptions['series'];
	price?: number;
	type?: string;
	desc?: string;
	showDropdown?: boolean;
	isAdmin?: boolean;
	isPublished?: boolean;
	status?: string;
	handlePublish?: (id: string | number, type: boolean) => Promise<void>;
	handleDelete?: (reportId: number | string) => void;
}
const TemplateReportCard: FC<ICommonGridProductItemProps> = ({
	id,
	name,
	category,
	img,
	color,
	series,
	price,
	type,
	desc,
	showDropdown = false,
	isAdmin = false,
	isPublished,
	status,
	handlePublish,
	handleDelete,
}) => {
	const { themeStatus, darkModeStatus } = useDarkMode();
	const [isExpanded, setIsExpanded] = useState<boolean>(false);
	const [publish, setPublish] = useState(isPublished);
	const [publishLoading, setPublishLoading] = useState<boolean>(false);

	const toggleReadMore = () => {
		setIsExpanded(!isExpanded);
	};

	const handlePublishClick = async () => {
		if (handlePublish) {
			setPublishLoading(true);
			await handlePublish(id, !publish);
			setPublish(!publish);
			setPublishLoading(false);
		}
	};

	const dummyOptions: ApexOptions = {
		colors: [color],
		chart: {
			type: 'line',
			width: 100,
			height: 35,
			sparkline: {
				enabled: true,
			},
		},
		tooltip: {
			theme: 'dark',
			fixed: {
				enabled: false,
			},
			x: {
				show: false,
			},
			y: {
				title: {
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					formatter(seriesName: string) {
						return '';
					},
				},
			},
		},
		stroke: {
			curve: 'smooth',
			width: 2,
		},
	};
	return (
		<Card>
			<CardHeader>
				<CardLabel>
					<CardTitle>
						{name}{' '}
						{type && (
							<Badge color='info' isLight className='ms-2'>
								{type}
							</Badge>
						)}
					</CardTitle>
					<CardSubTitle>{category}</CardSubTitle>

					{isAdmin && (
						<CardSubTitle className='d-flex gap-3 mt-2'>
							<Badge color={publish ? 'success' : 'info'} isLight>
								{publish ? 'Published' : 'Not published'}
							</Badge>
							<Badge color={status === 'generated' ? 'success' : 'info'} isLight>
								{status}
							</Badge>
						</CardSubTitle>
					)}
				</CardLabel>

				{showDropdown && (
					<CardActions>
						<Dropdown>
							<DropdownToggle hasIcon={false}>
								<Button icon='MoreHoriz' color={themeStatus} shadow='default' />
							</DropdownToggle>
							<DropdownMenu isAlignmentEnd>
								{status === 'generated' && (
									<DropdownItem>
										<Button
											icon={publish ? 'Unpublished' : 'PublishedWithChanges'}
											onClick={handlePublishClick}>
											{publish ? 'Unpublish' : 'Publish'}{' '}
											{publishLoading && <Spinner isSmall inButton isGrow />}
										</Button>
									</DropdownItem>
								)}
								{handleDelete && (
									<DropdownItem>
										<Button icon='Delete' onClick={() => handleDelete(id)}>
											Delete
										</Button>
									</DropdownItem>
								)}
							</DropdownMenu>
						</Dropdown>
					</CardActions>
				)}
			</CardHeader>

			<CardBody>
				<img
					src={img}
					alt=''
					style={{ height: '140px' }}
					className='mx-auto d-block img-fluid mb-3'
				/>
				<CardSubTitle>
					{desc && desc?.length > 81
						? isExpanded
							? desc
							: `${desc?.split(' ').slice(0, 16).join(' ')} ...`
						: desc}
				</CardSubTitle>
				{desc && desc?.length > 81 && (
					<span style={{ cursor: 'pointer', color: 'blue' }} onClick={toggleReadMore}>
						{isExpanded ? 'Show Less' : 'Read More'}
					</span>
				)}
			</CardBody>

			<CardFooter className='shadow-3d-container'>
				<Button
					color='dark'
					// style={{ backgroundColor: 'black', color: 'white' }}
					className={`w-100 mb-4 shadow-3d-up-hover shadow-3d-${
						darkModeStatus ? 'light' : 'dark'
					}`}
					tag='a'
					to={!isAdmin ? `/report-template/${id}` : `/view-report-template/${id}`}
					size='lg'>
					View Template
				</Button>
			</CardFooter>
		</Card>
	);
};

export default TemplateReportCard;
