import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { Html } from 'react-pdf-html';
import backgroundImage from '../../../assets/ainovaLogo/ainova-low-resolution-logo-black-on-white-background.png';
import PoppinsRegular from '../../../assets/fonts/Poppins-Regular.ttf';
import PoppinsBold from '../../../assets/fonts/Poppins-Bold.ttf';
import PoppinsItalic from '../../../assets/fonts/Poppins-Italic.ttf';

const stylesheet = {
	li: {
		marginBottom: '7px',
	},
};

// Register fonts
Font.register({
	family: 'Poppins',
	fonts: [
		{ src: PoppinsRegular, fontWeight: 400 },
		{ src: PoppinsBold, fontWeight: 700 },
		{ src: PoppinsItalic, fontStyle: 'italic', fontWeight: 400 },
	],
	format: 'truetype',
});

// Create styles
const styles = StyleSheet.create({
	page: {
		padding: 10,
		height: '100vh',
		fontFamily: 'Poppins',
		flexDirection: 'column',
	},
	cardContainer: {
		backgroundColor: '#fff',
		flex: 1,
		width: '97%',
		marginBottom: 15,
		alignSelf: 'center',
		fontWeight: 400,
		borderRadius: 10,
		padding: 10,
		position: 'relative',
	},
	textIdeaContainer: {
		marginTop: 15,
		backgroundColor: '#e2dff6',
		width: '97%',
		alignSelf: 'center',
		borderRadius: 8,
		padding: 10,
	},
	type: {
		marginVertical: 20,
		fontStyle: 'italic',
		fontWeight: 400,
		fontFamily: 'Poppins',
		textTransform: 'capitalize',
		fontSize: 25,
		textAlign: 'center',
	},
	textIdea: {
		marginTop: 15,
		fontSize: 20,
		textDecoration: 'underline',
		textAlign: 'center',
		fontWeight: 400,
	},
	idea: {
		fontSize: 12,
		color: '#626168',
		marginLeft: 10,
		marginVertical: 2,
		marginHorizontal: 20,
	},
	paragraph: {
		fontSize: 12,
		marginBottom: 10,
		textAlign: 'justify',
	},
	boldText: {
		fontWeight: 400, // Use fontWeight for bold text
	},
	underlineText: {
		textDecoration: 'underline',
	},
	htmlStringContainer: {
		marginHorizontal: 10,
	},
	ideaContainer: {
		marginTop: 15,
		marginBottom: 35,
	},
	backgroundImage: {
		opacity: 0.2,
		width: '100%',
		height: 'auto',
		position: 'absolute',
		minWidth: '100%',
		minHeight: '50%',
		top: '20%',
		textAlign: 'center',
		justifyContent: 'center',
		alignItems: 'center',
	},
});

// Create Document Component
const ReportDetailsPDF = (props: any) => {
	return (
		<Document>
			<Page size='A4' style={styles.page}>
				<View style={styles.cardContainer}>
					<View>
						<Text style={styles.type}>{props.type}</Text>
					</View>
					<Image src={backgroundImage} style={styles.backgroundImage} fixed />
					{!!props?.IdeaArr?.length && (
						<View style={styles.textIdeaContainer}>
							<Text style={styles.textIdea}>
								{props?.IdeaArr?.length === 1
									? 'Business Idea'
									: 'Your Questionnaire'}
							</Text>
							<View style={styles.ideaContainer}>
								{props?.IdeaArr?.length > 1 ? (
									props?.IdeaArr?.map((item: any, index: any) => (
										<Text key={index} style={styles.idea}>
											{index + 1}. {item}
										</Text>
									))
								) : (
									<Text style={[styles.idea, { textAlign: 'center' }]}>
										{props?.IdeaArr[0]}
									</Text>
								)}
							</View>
						</View>
					)}
					<View style={styles.htmlStringContainer}>
						<View style={{ marginTop: 20 }} fixed />
						<Html stylesheet={stylesheet} style={{ fontSize: 10 }}>
							{props.htmlString}
						</Html>
					</View>
				</View>
			</Page>
		</Document>
	);
};

export default ReportDetailsPDF;
