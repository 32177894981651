import React, { useRef, useState, useEffect, useMemo } from 'react';
import './MegaMenu.scss'; // Import your CSS file for styling
import Icon from '../icon/Icon';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function MegaMenu({ groupedData, setDropData, dropData, setShowSelectPlanErrorModal }: any) {
	const [isOpen, setIsOpen] = useState(false);
	const isFocus = useRef<any>(null);
	const { user } = useSelector((state: any) => state);
	const navigate = useNavigate();

	useEffect(() => {
		// Function to close the div when clicking outside of it
		function handleClickOutside(event: any) {
			if (isFocus.current && !isFocus.current.contains(event.target)) {
				setIsOpen(false);
			}
		}

		// Attach the click event listener to the document
		document.addEventListener('click', handleClickOutside);

		// Cleanup: Remove the event listener when the component is unmounted
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	const categories = [
		{
			title: 'Category 1',
			subcategories: ['Subcategory 1.1', 'Subcategory 1.2', 'Subcategory 1.3'],
		},
		{
			title: 'Category 2',
			subcategories: ['Subcategory 2.1', 'Subcategory 2.2', 'Subcategory 2.3'],
		},
		{
			title: 'Category 3',
			subcategories: [
				'Subcategory 3.1dsdsdsd',
				'Subcategory 3.2dsdsd',
				'Subcategory 3.3dsdsd',
			],
		},
		{
			title: 'Category 4',
			subcategories: ['Subcategory 4.1', 'Subcategory 4.2', 'Subcategory 4.3'],
		},
		{
			title: 'Category 42323asas',
			subcategories: ['Subcategory 4.1', 'Subcategory 4.2', 'Subcategory 4.3'],
		},
		{
			title: 'Category 4',
			subcategories: ['Subcategory 4.1', 'Subcategory 4.2', 'Subcategory 4.3'],
		},
		{
			title: 'Category 4',
			subcategories: ['Subcategory 4.1', 'Subcategory 4.2', 'Subcategory 4.3'],
		},
		{
			title: 'Category 4',
			subcategories: ['Subcategory 4.1', 'Subcategory 4.2', 'Subcategory 4.3'],
		},
		{
			title: 'Category 4',
			subcategories: ['Subcategory 4.1', 'Subcategory 4.2', 'Subcategory 4.3'],
		},
		// Add more categories and subcategories as needed
	];

	const optionFuc = (item: any) => {
		setIsOpen(!isOpen);
		if (item) {
			const isDisabled = user?.plan?.planId === '0' && item?.reportType !== 'simple';
			if (isDisabled) {
				setShowSelectPlanErrorModal(true);
			} else {
				setShowSelectPlanErrorModal(false);
				setDropData(item);
				navigate(`/questionnaire?report-type-id=${item?.id}`);
			}
		}
	};

	return (
		<div className='mega-menu' onBlur={() => setIsOpen(!isOpen)}>
			<div
				className={`mega-menu-toggle ${isOpen ? 'mega-menu-toggle-focus' : ''}`}
				ref={isFocus}
				onClick={() => setIsOpen(!isOpen)}>
				<span
					title={dropData.reportTitle}
					style={{ userSelect: 'none' }}
					className={dropData.reportTitle ? 'activeColor' : 'unactiveColor'}>
					{dropData.reportTitle || 'Select a Reports Type'}
				</span>
				<span className='icon'>
					<Icon
						icon='ArrowForwardIos'
						style={{ transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)' }}
					/>
				</span>
			</div>
			{isOpen && (
				<div
					className={`mega-menu-reuse ${
						categories?.length > 2 ? 'mega-menu-content-two' : 'mega-menu-content'
					}`}
					style={{ justifyContent: categories?.length > 4 ? 'start' : 'space-around' }}>
					{groupedData?.map((category: any) => (
						<div key={category?.title} className='menu-category'>
							<b className='menu-title' title={category?.title}>
								{category?.title}
							</b>
							<div className='menu-subcategories'>
								{category?.subcategories?.map((subcategory: any) => (
									<div
										key={subcategory}
										className='menu-subcategory'
										title={subcategory?.reportTitle}
										onClick={() => optionFuc(subcategory)}>
										{subcategory?.reportTitle}
									</div>
								))}
							</div>
						</div>
					))}
				</div>
			)}
		</div>
	);
}

export default MegaMenu;
