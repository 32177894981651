import Auth from '../pages/presentation/auth/Auth';
import DashboardPage from '../pages/presentation/dashboard/DashboardPage';
import QuestionNaire from '../pages/presentation/questionnaire/QuestionNaire';
import ReportsPages from '../pages/presentation/reports-page/ReportsPages';
import ReportsDetail from '../pages/presentation/reports-page/ReportsDetail';
import Page404 from '../pages/presentation/auth/Page404';
import PricingPage from '../pages/presentation/pricing-page/PricingPage';
import PricingSuccess from '../pages/presentation/pricing-page/PricingSuccess';
import ChangePassword from '../pages/presentation/auth/changepassword/ChangePassword';
import AllReports from '../pages/presentation/admin/allReports/AllReports';
import CreateReportType from '../pages/presentation/admin/createReportType/CreateReportType';
import UpdateCreateReportType from '../pages/presentation/admin/updateCreateReportType/UpdateCreateReportType';
import UpdateReport from '../pages/presentation/updateReport/UpdateReport';
import Setting from '../pages/presentation/setting/Setting';
import AllUser from '../pages/presentation/admin/allUser/AllUser';
import View from '../pages/presentation/admin/view/View';
import TemplateQuestionNaire from '../pages/presentation/admin/template-questionnaire';
import ReportTemplates from '../pages/presentation/report-templates';
import AdminReportTemplates from '../pages/presentation/admin/report-templates';
import ReportTemplateGeneration from '../pages/presentation/report-templates/ReportTemplateGeneration';
import ReportsFeedback from '../pages/presentation/admin/reports-feedback';

export const User = [
	{
		path: '/',
		component: <DashboardPage />,
	},
	{
		path: '/questionnaire',
		component: <QuestionNaire />,
	},
	{
		path: '/update-report/:id',
		component: <UpdateReport />,
	},
	{
		path: '/reports',
		component: <ReportsPages />,
	},
	{
		path: '/reportsDetail/:id/:type',
		component: <ReportsDetail />,
	},

	{
		path: '/setting',
		component: <Setting />,
	},
	{
		path: '/pricing',
		component: <PricingPage />,
	},
	{
		path: '/pricing-success',
		component: <PricingSuccess />,
	},

	{
		path: '*',
		component: <Page404 />,
	},
];

export const Admin = [
	{
		path: '/',
		component: <AllReports />,
	},
	{
		path: '/create-a-report-type',
		component: <CreateReportType />,
	},
	{
		path: '/update-create-a-report-type/:id',
		component: <UpdateCreateReportType />,
	},
	{
		path: '/all-report-templates',
		component: <AdminReportTemplates />,
	},
	{
		path: '/view-report-template/:id',
		component: <ReportTemplateGeneration />,
	},
	{
		path: '/all-users',
		component: <AllUser />,
	},
	{
		path: '/view/:id',
		component: <View />,
	},
	{
		path: '/pricing-success',
		component: <PricingSuccess />,
	},
	{
		path: '/template-questionnaire',
		component: <TemplateQuestionNaire />,
	},
	{
		path: '/all-feedback',
		component: <ReportsFeedback />,
	},
	
	{
		path: '*',
		component: <Page404 />,
	},
];

export const auth = [
	{
		path: '/auth-pages/login',
		component: <Auth />,
	},
	{
		path: '*',
		component: <Auth />,
	},
];

export const Pricing = [
	{
		path: '/pricing',
		component: <PricingPage />,
	},
	{
		path: '*',
		component: <PricingPage />,
	},
];

export const ChangePasswordScreen = [
	{
		path: '/change-password',
		component: <ChangePassword />,
	},
	{
		path: '*',
		component: <ChangePassword />,
	},
];
