import React, { useEffect, useState } from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { ADMIN_GET_REPORT } from '../../../Api/Query';
import Spinner from '../../../components/bootstrap/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import noReport from '../../../assets/svgImg/noReports.svg';
import { GETREPORTS } from '../../../reducer/Types';
import Page from '../../../layout/Page/Page';
import ReportIdeaHeader from '../../../components/report/reportIdeaHeader/ReportIdeaHeader';
import '../../../styles/pages/Report.scss';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReportDetailsPDF from '../reports-page/ReportDetailsPDF';
import Icon from '../../../components/icon/Icon';

const HTMLResponse = ({ htmlString }: any) => {
	console.log({ b: htmlString });
	return <div className='reportMain' dangerouslySetInnerHTML={{ __html: htmlString }} />;
};
// font-size: calc(1.5rem + 1.5vw);
const ReportTemplateGeneration = () => {
	const { id, type }: any = useParams();
	const dispatch = useDispatch();
	const [reportStatus, setReportStatus] = useState<string | undefined>('');

	const { getReport } = useSelector((state: any) => state);

	const {
		data: queryData,
		loading: queryLoading,
		error: queryError,
	} = useQuery(ADMIN_GET_REPORT, {
		variables: { reportTemplateId: id },
		pollInterval: reportStatus === 'GENERATING' ? 10000 : 0,
	});

	const [isloading, setIsLoading] = useState(true);
	const reportHtml = !!getReport?.stringifiedReport && JSON.parse(getReport?.stringifiedReport);
	const [htmlString, setHtmlString] = useState('');
	console.log({ a: getReport?.stringifiedReport });
	useEffect(() => {
		setHtmlString(reportHtml);
		console.log({ reportHtml });
	}, [getReport?.stringifiedReport]);

	// useEffect(() => {
	// 	subscribeToMore({
	// 		document: REPORT_SUBCRIPTION,
	// 		variables: { id: id },

	// 		updateQuery: (prev, { subscriptionData }) => {
	// 			if (!subscriptionData.data) return prev;
	// 			const Item = subscriptionData.data;
	// 			if (Item) {
	// 				setIsLoading(false);
	// 				dispatch({ type: GETREPORTS, payload: Item?.reportGenerated });
	// 			}
	// 		},
	// 	});
	// }, [subscribeToMore]);

	useEffect(() => {
		setReportStatus(queryData?.getAdminReport?.status);
		if (queryData?.getAdminReport?.status === 'GENERATING') {
			setIsLoading(true);
		} else if (queryData?.getAdminReport?.status === 'SUCCESS') {
			dispatch({ type: GETREPORTS, payload: queryData?.getAdminReport });
			setIsLoading(false);
		}
		if (queryError?.graphQLErrors) {
			setReportStatus('ERROR');
			setIsLoading(false);
			dispatch({ type: GETREPORTS, payload: [] });
		}
	}, [queryData?.getAdminReport, queryError?.graphQLErrors]);

	// useEffect(() => {
	// 	if (!status) {
	// 		setIsLoading(false);
	// 		dispatch({ type: GETREPORTS, payload: [] });
	// 	}
	// }, [message, status]);

	return (
		<PageWrapper>
			<React.Fragment>
				{isloading || queryLoading ? (
					<div
						className='d-flex align-items-center justify-content-center'
						style={{ height: '90vh' }}>
						<Spinner isGrow={true} color={'dark'} size={'5vh'} />
						<span style={{ paddingLeft: '10px' }}>
							Your report is generating, it may take from 30 seconds to couple of
							minutes to complete
						</span>
					</div>
				) : (
					<Page>
						<div className='reportDetail'>
							<div className='reportDetailSheet'>
								<div className='reportDetailheadContainer'>
									<h1 className='reportDetailhead'>{getReport?.title}</h1>
									{htmlString && (
										<PDFDownloadLink
											document={
												<ReportDetailsPDF
													type={getReport?.title}
													htmlString={htmlString}
												/>
											}
											fileName={`${getReport?.title}.pdf`}>
											<Icon
												icon='Download'
												size={'5x'}
												className='downloadIcon'
											/>
										</PDFDownloadLink>
									)}
								</div>
								<div
									className='shadow-lg  my-4  bg-white rounded d-block'
									style={{ padding: '2rem' }}>
									<React.Fragment>
										{htmlString ? (
											<HTMLResponse htmlString={htmlString} />
										) : (
											<h1 style={{ textAlign: 'center' }}>
												No Record Found{' '}
											</h1>
										)}
									</React.Fragment>
								</div>
							</div>
						</div>
					</Page>
				)}
			</React.Fragment>
		</PageWrapper>
	);
};

export default ReportTemplateGeneration;
