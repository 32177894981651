import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ThemeProvider } from 'react-jss';
import { ReactNotifications } from 'react-notifications-component';
import { useFullscreen, useWindowSize } from 'react-use';
import { ToastProvider } from 'react-toast-notifications';
import { TourProvider } from '@reactour/tour';
import ThemeContext from '../contexts/themeContext';
import Wrapper from '../layout/Wrapper/Wrapper';
import Portal from '../layout/Portal/Portal';
import { Toast, ToastContainer } from '../components/bootstrap/Toasts';
import useDarkMode from '../hooks/useDarkMode';
import COLORS from '../common/data/enumColors';
import { getOS } from '../helpers/helpers';
import steps, { styles } from '../steps';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import DefaultAside from '../pages/_layout/_asides/DefaultAside';
import { useDispatch, useSelector } from 'react-redux';
import { withoutLayoutRoutes } from '../helpers/constants';
import { useLocation } from 'react-router';
import { ErrorBoundary } from '../errorBoundary/ErrorBoundary';

// export const ErrorComponent = () => {
// 	throw new Error('Intentional error');
//   };

const App = () => {
	const { user } = useSelector((state: any) => state);
	const [currentPath, setCurrentPath] = useState('/' + window.location.pathname.split('/')[1]);
	const { asideStatus } = useContext(ThemeContext);
	const { width } = useWindowSize();

	const location = useLocation();

	useEffect(() => {
		setCurrentPath('/' + window.location.pathname.split('/')[1]);
	}, [location]);

	getOS();

	dayjs.extend(localizedFormat);
	dayjs.extend(relativeTime);

	/**
	 * Dark Mode
	 */

	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};

	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
			document.documentElement.setAttribute('data-bs-theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
			document.documentElement.removeAttribute('data-bs-theme');
		};
	}, [darkModeStatus]);

	/**
	 * Full Screen
	 */
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	return (
		// <Router />
		<ThemeProvider theme={theme}>
			<ErrorBoundary>
				<ToastProvider components={{ ToastContainer, Toast }}>
					<TourProvider
						steps={steps}
						styles={styles}
						showNavigation={false}
						showBadge={false}>
						<div
							ref={ref}
							className='app'
							style={{
								backgroundColor: fullScreenStatus ? 'var(--bs-body-bg)' : undefined,
								zIndex: fullScreenStatus ? 1 : undefined,
								overflow: fullScreenStatus ? 'scroll' : undefined,
							}}>
							{width < Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE) &&
							asideStatus ? (
								<DefaultAside />
							) : width > Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE) ? (
								!!user?.token &&
								!!user?.planId &&
								!withoutLayoutRoutes.includes(currentPath) && <DefaultAside />
							) : (
								<></>
							)}
							<Wrapper />
						</div>
						<Portal id='portal-notification'>
							<ReactNotifications />
						</Portal>
					</TourProvider>
				</ToastProvider>
				{/* <ErrorComponent /> */}
			</ErrorBoundary>
		</ThemeProvider>
	);
};

export default App;
