import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Spinner from '../../../components/bootstrap/Spinner';
import Page from '../../../layout/Page/Page';
import ReportIdeaHeader from '../../../components/report/reportIdeaHeader/ReportIdeaHeader';
import { useParams } from 'react-router-dom';
import { GET_TEMPLATE } from '../../../Api/Query';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReportDetailsPDF from '../reports-page/ReportDetailsPDF';
import Icon from '../../../components/icon/Icon';

export interface IAppProps {}

const HTMLResponse = ({ htmlString }: any) => {
	return <div className='reportMain' dangerouslySetInnerHTML={{ __html: htmlString }} />;
};

export function ReportTemplateDetails(props: IAppProps) {
	const [htmlString, setHtmlString] = useState('');
	const { id: templateId } = useParams();

	const { loading, error, data } = useQuery(GET_TEMPLATE, {
		variables: { templateId },
	});

	useEffect(() => {
		if (!loading && data) {
			const { status, stringifiedReport } = data.getTemplate;
			if (status) {
				setHtmlString(JSON.parse(stringifiedReport));
			}
		}
	}, [loading, data]);

	return (
		<PageWrapper>
			{loading ? (
				<div
					className='d-flex align-items-center justify-content-center'
					style={{ height: '90vh' }}>
					<Spinner isGrow={true} color={'dark'} size={'5vh'} />
					<span style={{ paddingLeft: '10px' }}>Fetching the report template...</span>
				</div>
			) : (
				<Page>
					<div className='reportDetail'>
						<div className='reportDetailSheet'>
							<div className='reportDetailheadContainer'>
								<h1 className='reportDetailhead'>{data?.getTemplate?.title}</h1>
								{htmlString && (
									<PDFDownloadLink
										document={
											<ReportDetailsPDF
												type={data?.getTemplate?.title}
												htmlString={htmlString}
											/>
										}
										fileName={`${data?.getTemplate?.title}.pdf`}>
										<Icon
											icon='Download'
											size={'5x'}
											className='downloadIcon'
										/>
									</PDFDownloadLink>
								)}
							</div>
							<div
								className='shadow-lg  my-4  bg-white rounded d-block'
								style={{ padding: '2rem' }}>
								<HTMLResponse htmlString={htmlString} />
							</div>
						</div>
					</div>
				</Page>
			)}
		</PageWrapper>
	);
}
