import React, { useState, useRef } from 'react';
import Textarea from './bootstrap/forms/Textarea';
import Icon from './icon/Icon';
import { GETQUESTION, MESSAGE, STATUS } from '../reducer/Types';
import { useDispatch, useSelector } from 'react-redux';

interface QuestionNaireCardBodyProps {
	Question: any;
	formik: any;
}

const QuestionNaireCardBody: React.FC<QuestionNaireCardBodyProps> = ({ Question, formik }) => {
	const [focus, setFocus] = useState<boolean>(false);
	const { getQuestion } = useSelector((state: any) => state);
	const fileInputRef = useRef<HTMLInputElement>(null);
	const dispatch = useDispatch();
	const [filePreview, setFilePreview] = useState<string | null>(null);

	const allowedTypes = ['.pdf', '.csv', '.xlsx', '.txt', '.jpg', '.jpeg', '.png', '.webp'];

	const handleFileInputChange = (id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files ? event.target.files[0] : null;
		const Question = [...getQuestion];
		if (file) {
			const fileType = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
			if (!allowedTypes.includes(fileType)) {
				dispatch({
					type: MESSAGE,
					payload: `Invalid file type. Please select a .pdf, .csv, .txt, .xlsx, .jpg, .jpeg, or .png file.`,
				});
				dispatch({ type: STATUS, payload: false });
				dispatch({
					type: GETQUESTION,
					payload: Question.map((item: any) => {
						if (item.id === id) {
							return { ...item, selectedFileName: '', selectedFile: '' };
						}
						return item;
					}),
				});
				formik.setValues({
					...formik.values,
					[id]: {
						...formik.values[id],
						fileName: null,
						fileType: null,
						fileUrl: null,
						file: null,
					},
				});
				setFilePreview(null);
			} else {
				const reader = new FileReader();
				reader.onloadend = () => {
					setFilePreview(reader.result as string);
				};
				reader.readAsDataURL(file);

				dispatch({
					type: GETQUESTION,
					payload: Question.map((item: any) => {
						if (item.id === id) {
							return { ...item, selectedFileName: file.name, selectedFile: file };
						}
						return item;
					}),
				});

				formik.setValues({
					...formik.values,
					[id]: {
						...formik.values[id],
						fileName: file.name,
						fileType: file.name.substring(file.name.lastIndexOf('.') + 1).toUpperCase(),
						fileUrl: null,
						file: file,
					},
				});
			}
		}
	};

	const handleIconClick = () => {
		fileInputRef.current?.click();
	};

	const handleRemoveFile = (id: string) => {
		const Question = [...getQuestion];
		dispatch({
			type: GETQUESTION,
			payload: Question.map((item: any) => {
				if (item.id === id) {
					return { ...item, selectedFileName: '', selectedFile: '' };
				}
				return item;
			}),
		});
		formik.setValues({
			...formik.values,
			[id]: {
				...formik.values[id],
				fileName: null,
				fileType: null,
				fileUrl: null,
				file: null,
			},
		});
		setFilePreview(null);
		if (fileInputRef.current) {
			fileInputRef.current.value = ''; // Reset the file input value
		}
	};

	return (
		<div className='col-12 gap-1'>
			<h5 className='ps-2'>{Question?.question}</h5>
			<div
				className={`inputUpdateReport ${focus && 'inputUpdateReportTwo'}`}
				onFocus={() => setFocus(true)}
				onBlur={() => setFocus(false)}>
				<Textarea
					maxLength={Question?.maxLength || null}
					minLength={Question?.minLength || null}
					placeholder='Write Answer'
					value={formik.values[Question.id]?.[Question.id]}
					id={Question.id}
					name={`${Question.id}.${Question.id}`}
					onChange={formik.handleChange}
					rows={3}
				/>

				<div className='lengthShowContainer'>
					<div className='lengthShow'>
						{Question?.minLength > 0 && Question?.maxLength > 0 && (
							<span>
								<span>{`${
									formik.values[Question.id]?.[Question?.id]?.length || 0
								} / ${Question?.maxLength}`}</span>
								<span>{`(min. ${Question?.minLength})`}</span>
							</span>
						)}
					</div>
					{Question?.enableFileInput === true && (
						<div
							data-toggle='tooltip'
							data-placement='right'
							title='Attach a file'
							className='file'>
							<input
								type='file'
								ref={fileInputRef}
								style={{ display: 'none' }}
								onChange={handleFileInputChange(Question.id)}
								accept={allowedTypes.join(',')}
							/>
							<Icon
								icon='Attachment'
								className='fileIcon'
								onClick={handleIconClick}
							/>
							<span>{Question?.selectedFileName}</span>
						</div>
					)}
				</div>
			</div>
			{filePreview && (
				<div className='file-preview-container border rounded p-2 my-2 position-relative'>
					<div style={{ width: 'fit-content' }} className='h-100 position-relative'>
						{filePreview.startsWith('data:image') ? (
							<img
								style={{ height: '100px' }}
								className='file-preview img-thumbnail'
								src={filePreview}
								alt='File preview'
							/>
						) : (
							<div
								className='bg-white shadow p-3 rounded file-preview d-flex align-items-center flex-column'
								rel='noopener noreferrer'>
								<Icon size='4x' className='border-rounded' icon='InsertDriveFile' />
								{formik.values[Question.id]?.fileName}
							</div>
						)}

						<Icon
							size='lg'
							style={{ top: '-6px', right: '-6px' }}
							className='cursor-pointer bg-white border-rounded position-absolute'
							icon='Close'
							onClick={() => handleRemoveFile(Question.id)}
						/>
					</div>
				</div>
			)}
			{/* <div style={{ height: '100px' }} className='w-100 p-3 border border-top-0 rounded'>
				<img className='h-100' src={Img} alt='' />
			</div> */}
		</div>
	);
};

export default QuestionNaireCardBody;
